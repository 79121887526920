import React, { useEffect, useRef, useState } from 'react';
import { ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import { reaction } from 'mobx';
import { IGiftWithPurchaseProps } from './gift-with-purchase.props.autogenerated';
import { IGiftWithPurchaseData } from './gift-with-purchase.data';
import { availableGiftsWithPurchase_DLXAsync } from '../../actions/DataActionExtension.g';
import { IGiftWithPurchaseApiResponse_DLX } from '../../actions/DataServiceEntities.g';
import { getCartState } from '@msdyn365-commerce/global-state';
import { addCartLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { getEstimatedAvailabilityAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { trackAddToCartFromGWP } from '../../themes/venchi/utilities/datalayer';

export interface IGiftWithPurchaseViewProps extends IGiftWithPurchaseProps<IGiftWithPurchaseData> {
    modalVisible: boolean;
    promotionProps: IGiftWithPurchasePromotionProps;
    closePopupCallback(): void;
}

export interface IGiftWithPurchasePromotionProps {
    gwpType: GiftWithPurchaseType;
    numberProductsOfGift: number;
    productSelection: ProductSearchResult[];
}

export enum GiftWithPurchaseType {
    AUTO = 0,
    MANUALADD = 1
}

const GiftWithPurchase: React.FC<IGiftWithPurchaseProps<IGiftWithPurchaseData>> = props => {
    const [modalVisible, setModalVisible] = useState(false); // State to manage modal visibility
    const [gwpData, setGwpData] = useState<IGiftWithPurchasePromotionProps | null>(null);
    const productAddedAttempt = useRef(false); // Create ref to track if product is added

    if (typeof window === 'undefined') {
        return null;
    }
    //@ts-ignore
    const isOrderConfirmPage = window.___initialData___.requestContext.telemetryPageName.toLowerCase() === 'order confirmation';
    if (isOrderConfirmPage) {
        return null;
    }

    const closePopup = () => {
        if (modalVisible) {
            setModalVisible(false);
            setGwpData(null);
        }
    };
    const baseImageUrl: string = props.context.request.apiSettings.baseImageUrl;
    useEffect(() => {
        // Add reaction to listen for changes in cart lines
        const disposeReaction = reaction(
            () => props.data.cart.result?.cart.TotalItems!,
            async (totalItems: number) => {
                const currentURLPathName = props.context.request.url.requestUrl.pathname.replace(/\/$/, '');
                const defaultRoutes = props.context.request.app?.routes;
                const isOrderConfirmPage_temp = defaultRoutes.orderConfirmation?.destinationUrl?.replace(/\/$/, '') === currentURLPathName;
                if (
                    totalItems &&
                    !isOrderConfirmPage_temp &&
                    !productAddedAttempt.current &&
                    !(gwpData && gwpData.gwpType === GiftWithPurchaseType.AUTO) &&
                    document.visibilityState === 'visible'
                ) {
                    try {
                        const response: IGiftWithPurchaseApiResponse_DLX = await availableGiftsWithPurchase_DLXAsync(
                            { callerContext: props.context.actionContext },
                            props.data.cart.result?.cart.Id!
                        );
                        if (
                            // in questo caso considero che c'è almeno un prodotto da aggiungere
                            response &&
                            response.GWPItems &&
                            response.GWPItems.length > 0 &&
                            response.GWPItems[0].Items &&
                            response.GWPItems[0].Items.length > 0 &&
                            response.GWPItems[0].Qty &&
                            response.GWPItems[0].Qty > 0
                        ) {
                            const firstGWPItem = response.GWPItems[0];
                            const gwpTypeTemp =
                                (firstGWPItem.Items?.length ?? 0) > 1 ? GiftWithPurchaseType.MANUALADD : GiftWithPurchaseType.AUTO;
                            if (gwpTypeTemp === GiftWithPurchaseType.AUTO && firstGWPItem.Items?.length === 1) {
                                // let productIds: number[] = [];
                                // productIds = firstGWPItem!.Items!
                                //     // Map to array of ProductId, also filtering out any undefined or null values
                                //     .map(item => item.ProductId)
                                //     .filter(productId => productId !== undefined && productId !== null) as number[];
                                // const productAvailabilityInfo = availabilityResult.AggregatedProductInventoryAvailabilities.find(aggregatedData => aggregatedData.ProductId === firstGWPItem.Items![0].ProductId!)
                                //TODO fix this call and remove harcode
                                const availabilityResult = await getEstimatedAvailabilityAsync(
                                    { callerContext: props.context.actionContext },
                                    {
                                        ProductIds: [firstGWPItem.Items[0].ProductId!],
                                        DefaultWarehouseOnly: true,
                                        QuantityUnitTypeValue: 2
                                    }
                                );
                                if (
                                    availabilityResult &&
                                    availabilityResult.AggregatedProductInventoryAvailabilities &&
                                    availabilityResult.AggregatedProductInventoryAvailabilities?.length > 0 &&
                                    availabilityResult.AggregatedProductInventoryAvailabilities[0]
                                        .MaximumPurchasablePhysicalAvailableQuantity &&
                                    availabilityResult.AggregatedProductInventoryAvailabilities[0]
                                        .MaximumPurchasablePhysicalAvailableQuantity >
                                        2 * firstGWPItem.Qty!
                                ) {
                                    productAddedAttempt.current = true;

                                    const cartLinesToAdd = [
                                        {
                                            ItemId: firstGWPItem.Items[0].ItemId,
                                            ProductId: firstGWPItem.Items[0].ProductId,
                                            Quantity: firstGWPItem.Qty,
                                            TrackingId: '',
                                            ExtensionProperties: [
                                                {
                                                    Key: 'GWPItem',
                                                    Value: { BooleanValue: true }
                                                },
                                                {
                                                    Key: 'GWPType',
                                                    Value: { StringValue: 'AUTO' }
                                                },
                                                {
                                                    Key: 'venchiProductListName',
                                                    Value: { StringValue: 'GWP' }
                                                },
                                                {
                                                    Key: 'venchiProductCategoryHierarchy',
                                                    Value: { StringValue: 'GWP||||' }
                                                }
                                            ]
                                        }
                                    ];
                                    const cartState = await getCartState(props.context.actionContext);
                                    addCartLinesAsync(
                                        { callerContext: props.context.actionContext },
                                        cartState.cart.Id,
                                        cartLinesToAdd,
                                        cartState.cart.Version
                                    )
                                        .then(async () => {
                                            // Code here will only run if addCartLinesAsync was successful
                                            await cartState.refreshCart({});
                                            if (firstGWPItem.Items?.[0]) {
                                                trackAddToCartFromGWP(
                                                    props.context,
                                                    {
                                                        RecordId: firstGWPItem.Items[0].ProductId || 0,
                                                        ItemId: firstGWPItem.Items[0].ItemId,
                                                        Name: firstGWPItem.Items[0].Description,
                                                        BasePrice: 0,
                                                        Price: 0,
                                                        AdjustedPrice: 0,
                                                        ProductTypeValue: 0
                                                    },
                                                    firstGWPItem.Qty || 0
                                                );
                                            }
                                            const gwpProps: IGiftWithPurchasePromotionProps = {
                                                gwpType: gwpTypeTemp,
                                                numberProductsOfGift: firstGWPItem.Qty || 0,
                                                productSelection: firstGWPItem.Items!.map(
                                                    product =>
                                                        ({
                                                            ItemId: product.ItemId,
                                                            Name: product.Description,
                                                            Price: 0,
                                                            RecordId: product.ProductId,
                                                            ProductNumber: product.ProductId,
                                                            Description: product.Description,
                                                            BasePrice: 0,
                                                            PrimaryImageUrl: `${baseImageUrl}Products/${product.ItemId!}_000_001.jpg`
                                                        } as ProductSearchResult)
                                                )
                                            };
                                            productAddedAttempt.current = false;
                                            if (!modalVisible) {
                                                setModalVisible(true);
                                            }
                                            setGwpData(gwpProps);

                                            // Add any other actions that should only occur if addCartLinesAsync was successful
                                            // ...
                                        })
                                        .catch(error => {
                                            productAddedAttempt.current = false;
                                            // Handle error. Error handling logic here.
                                            console.error('Error adding gwp cart lines: ', error);
                                            // Optional: Provide user feedback about the error via UI
                                        });
                                }
                            } else {
                                //TODO gestire caso alternativo con più prodotti
                            }
                        }
                    } catch (error) {
                        console.error('Error fetching GWP data:', error);
                    }
                }
            }
        );

        return () => {
            // Clean up the reaction when the component unmounts
            disposeReaction(); // Dispose of the reaction to avoid memory leaks
        };
    }, []);

    return props.renderView({
        ...props,
        modalVisible: modalVisible,
        promotionProps: gwpData,
        closePopupCallback: closePopup
    }) as React.ReactElement;
};

export default GiftWithPurchase;
